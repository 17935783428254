import React, { useEffect, useRef, useState } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import { AppLayout, Box, Container, SplitPanel } from '@amzn/awsui-components-react';
import CONSTANTS from 'src/utils/constants';
import TopYtdBalancesGrid from './TopYtdBalancesGrid';
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';
import './PeriodChargesView.scss';
import BottomYtdBalancesGrid from './BottomYtdBalancesGrid';
import YtdPeriodBalance from 'src/models/periodCharges/YtdPeriodBalance';
import { ACTIONS, periodChargesReducer } from 'src/services/period-charges/PeriodChargesReducer';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { initialState, PeriodChargesState } from 'src/services/period-charges/PeriodChargesState';
import TPEAction from 'src/models/common/TPEAction';
import StringUtils from 'src/utils/stringUtils';
import { GlobalAppContext } from '../App';
import TPESearchRequest, { DbConjunctor, DbOperator } from 'src/models/common/TPESearchRequest';
import { CLI } from 'src/models/common/CLI';
import { ExportPeriodChargesWSMessage } from 'src/models/periodCharges/ExportPeriodChargesWSMessage';


export type ContextType = {
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    state: PeriodChargesState    
}

const PeriodChargesProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <PeriodChargesContext.Provider value={providerValue}>
            {children}
        </PeriodChargesContext.Provider>
    );
}
export const PeriodChargesContext = React.createContext(null as unknown as ContextType);

export default function PeriodChargesView(props: { services: ServiceCollection }) {
    const {services} = props;
    const { userProfile } = React.useContext(GlobalAppContext);
    const [state, dispatch] = useReducerWithLogger(periodChargesReducer, initialState);
    const [bottomPanelHeader, setBottomPanelHeader] = useState('Select one or multiple CLIs to view prior period balance');

    const [lovs, lovsLoading, lovsError] = services.periodChargesService.getLOVs();

    const [clisAutoSuggestList, setClisAutoSuggestList] = useState([] as string[]);
    const [bottomGridItems, setBottomGridItems] = useState([] as YtdPeriodBalance[]);
    const [showComingSoonModal, setShowComingSoonModal] = useState(false);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [searchCLI, setSearchCLI] = useState(undefined as CLI | undefined);
    const [activeCLIsFlag, setActiveCLIsFlag] = useState('');
    const [refreshPeriodChargesFlag, setRefreshPeriodChargesFlag] = useState('');
    const [submitAdjustmentPayload, setSubmitAdjustmentPayload] = useState(null as any);
    const [selectedTopGridItem, setSelectedTopGridItem] = useState(null as YtdPeriodBalance | null);
    const [localSearchFilter, setLocalSearchFilter] = React.useState(undefined as any | undefined);

    useEffect(() => {
        setActiveCLIsFlag(new Date().toDateString());
        const webSocketListener = setupWebSocketEvents(services, dispatch);


        return () => {
            // Anything in here is fired on component unmount.
            services.atpWebSocketApiService.removeListener('',webSocketListener);
        }
    },[])

    const searchParams = React.useMemo(() => {
        const searchYetToStartRequest = new TPESearchRequest();
        if (localSearchFilter == null){
            return searchYetToStartRequest;
        }
        searchYetToStartRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: [
                {
                    attributeName: "calculation_status",
                    operator: DbOperator.IN,
                    values: ["Active"]
                }
            ]
        };
        if (localSearchFilter != null && localSearchFilter != ''){
            searchYetToStartRequest.filterExpression.expressions.push({
                attributeName: "calculation_number",
                operator: DbOperator.EQUALS,
                values: [localSearchFilter]
            });
            searchYetToStartRequest.searchStartedDate = new Date();
        }
        return searchYetToStartRequest
    }, [localSearchFilter])


    const [allActiveClisResult, allActiveClisLoading, allActiveClisError] = services.cliService.getCLIs(activeCLIsFlag, undefined, undefined, CONSTANTS.ACTIVE_STATUS);
    const [searchCLIsResult, isSearchingCLIs, searchCLIsError] = services.calculationsService.searchCalculationLines(searchParams);
    const [ytdBalances, ytdBalancesLoading, ytdBalancesError] = services.periodChargesService.getPeriodCharges(refreshPeriodChargesFlag, searchCLI?.calculationNumber || '');
    const [saveAdjustmentResponse, isSavingAdjustment, saveAdjustmentError] = services.periodChargesService.saveAdjustment(submitAdjustmentPayload);

    useEffect(() => {
        if (searchCLIsResult == null){
            return;
        }
        if (lovs != null) {
            const matchingClis = searchCLIsResult.clis;
            if (matchingClis.length === 1) {
                dispatch(ACTIONS.SET_SELECTED_PERIOD_CHARGES.withPayload([]));
                setSelectedTopGridItem(null);
                setSearchCLI(matchingClis[0]);
                setRefreshPeriodChargesFlag(new Date().toISOString());
            }
        }
    }, [searchCLIsResult])

    useEffect(() => {
        if (allActiveClisResult.length === 0){
            return;
        }
        setClisAutoSuggestList(allActiveClisResult);
    }, [allActiveClisResult])


    useEffect(() => {
        const topGridItemsList = ytdBalances.filter(x => x.isCurrentPeriod === true)
        dispatch(ACTIONS.SET_PERIOD_CHARGES.withPayload(topGridItemsList));
        if (topGridItemsList.length === 1) {
            dispatch(ACTIONS.SET_FIRST_PERIOD_CHARGE_AS_SELECTED);
        }

        if (selectedTopGridItem?.calculationNumber === submitAdjustmentPayload?.calculationNumber) {
            setBottomGridItems(ytdBalances.filter(x => !x.isCurrentPeriod).sort((a,b) => b.periodId.localeCompare(a.periodId) || a.createDate - b.createDate));
        }
        setRefreshPeriodChargesFlag('');
    }, [ytdBalances])

    useEffect(() => {
        dispatch(ACTIONS.SET_IS_SEARCHING_PERIOD_CHARGES.withPayload(ytdBalancesLoading));
    }, [ytdBalancesLoading])
    
    useEffect(() => {
        dispatch(ACTIONS.SET_PERIOD_CHARGES_ERROR.withPayload(ytdBalancesError));
    }, [ytdBalancesError])

    useEffect(() => {
        dispatch(ACTIONS.SET_IS_SAVING_ADJUSTMENT.withPayload(isSavingAdjustment));
    }, [isSavingAdjustment])
    
    useEffect(() => {
        dispatch(ACTIONS.SET_SAVE_ADJUSTMENT_ERROR.withPayload(saveAdjustmentError));
    }, [saveAdjustmentError])

    useEffect(() => {
        if (saveAdjustmentResponse != null){
            services.messageService.showSuccessAutoDismissBanner(`Adjustment has been made to the beginning balance of CLI ${saveAdjustmentResponse.calculationNumber}`);
            dispatch(ACTIONS.SET_SAVE_ADJUSTMENT_RESPONSE.withPayload(saveAdjustmentResponse));
            dispatch(ACTIONS.SET_PERIOD_CHARGES.withPayload([]));
            setSelectedTopGridItem(null);
            setRefreshPeriodChargesFlag(new Date().toISOString());
        }
    }, [saveAdjustmentResponse])

    const onFilterChanged = (filterValues: {selectedCLI: string, selectedYear: string, selectedAssignee: string, selectedStatus: string}) => {
        if (StringUtils.isNullOrEmpty(filterValues?.selectedCLI)){
            resetSearchForm();
            return;
        }
        if (filterValues.selectedCLI.length < 4){
            resetSearchForm();
            return;
        }
        setLocalSearchFilter(filterValues.selectedCLI);
    }

    const resetSearchForm = () => {
        dispatch(ACTIONS.SET_PERIOD_CHARGES.withPayload([]));
        setLocalSearchFilter(null);
    }

    const onExportAllCLIsClicked = () => {
        dispatch(ACTIONS.SET_EXPORT_PERIOD_CHARGES_STATUS.withPayload("Starting process..."));
        services.atpWebSocketApiService.exportPeriodCharges({});
    }
    
    const onAdjustBalanceSubmitted = (adjustmentBaseAmount:number, adjustmentTotalAmount: number, calculationNumber: string) => {
        setSubmitAdjustmentPayload({calculationNumber, adjustmentBaseAmount, adjustmentTotalAmount});
    }
    const onSelectionChanged = (selectedItems:any[]) => {
        if (selectedItems == null || selectedItems.length === 0){
            setBottomPanelHeader('Select one or multiple CLIs to view prior period balance');
            setSplitPanelOpen(false);            
        }
        else {
            setRefreshPeriodChargesFlag('');
            setBottomPanelHeader(`CLI ${selectedItems[0].calculationNumber}`);
            setSelectedTopGridItem(selectedItems[0]);
            setSplitPanelOpen(true);
            setBottomGridItems(ytdBalances.filter(x => !x.isCurrentPeriod).reverse());
        }
    }


    return <Container className="polaris-content-container period-balances-container">
        <PeriodChargesProvider state={state} services={services} dispatch={dispatch}>
            <AppLayout
                disableContentPaddings={true}
                contentType='table'
                navigationHide={true}
                toolsHide={true}
                splitPanelOpen={splitPanelOpen}
                splitPanel={<SplitPanel header={bottomPanelHeader}>
                        <Box padding="xxl">
                            <BottomYtdBalancesGrid 
                                cli={searchCLI}
                                data={bottomGridItems} 
                                onAdjustBalanceSubmitted={onAdjustBalanceSubmitted}
                                 />
                        </Box>
                    </SplitPanel>}
                content={
                    <TopYtdBalancesGrid 
                        filterLOVs={lovs}
                        clisAutoSuggestList={clisAutoSuggestList}
                        onFilterChanged={onFilterChanged} 
                        onExportAllCLIs={onExportAllCLIsClicked} 
                        onSelectionChanged={onSelectionChanged} /> 
                }
            />
            <TPEBasicModal 
                title='Feature coming soon' 
                action={ACTION_TYPE.OK_ONLY} 
                visible={showComingSoonModal} 
                onConfirm={() => setShowComingSoonModal(false)} 
                onCancel={() => setShowComingSoonModal(false)}>This feature is coming soon!</TPEBasicModal>
        </PeriodChargesProvider>
    </Container>
}

function setupWebSocketEvents(services: ServiceCollection, dispatch: React.Dispatch<TPEAction>) {

    const listener = (x:any) => {
        const messagePayload = JSON.parse(x);
        if (messagePayload.messageType === CONSTANTS.WEB_SOCKET_API_ROUTES.EXPORT_PERIOD_CHARGES){
            const exportMessage = messagePayload as ExportPeriodChargesWSMessage;
            switch(exportMessage.status){
                case CONSTANTS.PROCESSING_STATUS.STARTED:
                    dispatch(ACTIONS.SET_EXPORT_PERIOD_CHARGES_STATUS.withPayload("Exporting..."));
                    break;
                case CONSTANTS.PROCESSING_STATUS.SUCCEEDED:
                    dispatch(ACTIONS.SET_EXPORT_PERIOD_CHARGES_STATUS.withPayload("Downloading..."));
                    dispatch(ACTIONS.SET_EXPORT_PERIOD_CHARGES_FILE_URL.withPayload(JSON.parse(exportMessage.message).s3Url));
                    setTimeout(() => {
                        dispatch(ACTIONS.SET_EXPORT_PERIOD_CHARGES_STATUS.withPayload(null));
                    },3000)
                    break;
            }
        }
    };
    services.atpWebSocketApiService.addListener(listener);
    return listener;
}