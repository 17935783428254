import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import React from "react";

type ComponentProps = {
    currentPageIndex: number,
    pagesCount: number,
    pageSize: number,
    disabled: boolean
    handlePageChange: (pageNumber: number, pageSize: number) => void
}

export default function (props: ComponentProps) {
    return (
        <Pagination currentPageIndex={props.currentPageIndex} pagesCount={props.pagesCount}
                    onNextPageClick={({detail}) => props.handlePageChange(detail.requestedPageIndex, props.pageSize)}
                    onChange={({detail}) => props.handlePageChange(detail.currentPageIndex, props.pageSize)}
                    onPreviousPageClick={({detail}) => props.handlePageChange(detail.requestedPageIndex, props.pageSize)}
                    disabled={props.disabled}
        />
    )
}