import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import YtdPeriodBalance from 'src/models/periodCharges/YtdPeriodBalance';
import { Box, Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import ArrayUtils from 'src/utils/arrayUtils';
import BalanceAdjustmentModal from './BalanceAdjustmentModal';
import { PeriodChargesContext } from './PeriodChargesView';
import { getPermissions } from '../AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import { CLI } from 'src/models/common/CLI';
import CONSTANTS from 'src/utils/constants';

export default function BottomYtdBalancesGrid(props: {
    data:YtdPeriodBalance[],
    cli?: CLI,
    onAdjustBalanceSubmitted: (adjustmentBaseAmount:number, adjustmentTotalAmount: number, calculationNumber: string) => void;
}) {
    const { state, services } = useContext(PeriodChargesContext);
    const { balanceAdjustmentIsSaving, balanceAdjustmentError, saveAdjustmentResponse } = state;
    const {data, cli, onAdjustBalanceSubmitted} = props;
    const year = ArrayUtils.isNullOrEmpty(data)? '' : data[0].year;
    const [adjustmentModalVisible, setAdjustmentModalVisible] = useState(false);
    const { canEdit } = getPermissions(AppModules.PERIOD_CHARGES);
    const cliHasBeatQualifier = CONSTANTS.BEAT_QUALIFIERS.includes(cli?.beat || "");

    const columnDefinitions = useMemo(() => {
        const columns = [
        {
            id: "month",
            header: "Month",
            cell: (e: YtdPeriodBalance) => e.month,
            width: 110,
            minWidth: 110,
        },
        {
            id: "adjustment",
            header: "Montly Adjustment",
            cell: (e: YtdPeriodBalance) => <Box float="right">{e.totalAmount == null? '-' : services.formattingService.formatNumber(e.totalAmount, true, 2)}</Box>,
            width: 200,
            minWidth: 110,
        },
        {
            id: "beginningBalance",
            header: "YTD beginning balance",
            cell: (e: YtdPeriodBalance) => <Box float="right">{e.ytdBeginningBalance == null? '-' : services.formattingService.formatNumber(e.ytdBeginningBalance, true, 2)}</Box>,
            width: 200,
            minWidth: 150
        },
        {
            id: "source",
            header: "Source",
            cell: (e: YtdPeriodBalance) => e.source,
            width: 250,
            minWidth: 150
        },
        {
            id: "updatedOn",
            header: "Updated on",
            cell: (e: YtdPeriodBalance) => e.updatedOn || "-",
            width: 200,
            minWidth: 170
        }
        ];
        if (cliHasBeatQualifier) {
            columns.splice(2,0,{
                id: "baseAmount",
                header: "Base amount",
                cell: (e: YtdPeriodBalance) => <Box float="right">{e.baseAmount == null? '-' : services.formattingService.formatNumber(e.baseAmount, true, 2)}</Box>,
            width: 180,
                minWidth: 110,
            });
        }
        return columns;
    }, [cliHasBeatQualifier]);


    useEffect(() => {
        if (saveAdjustmentResponse != null){
            setAdjustmentModalVisible(false);
        }
    }, [saveAdjustmentResponse]);

    return <>
            <Table 
                columnDefinitions={columnDefinitions}
                items={data}
                loadingText="Loading resources"
                resizableColumns
                empty={
                    <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit">
                        <SpaceBetween size="m">
                        <b>No resources</b>
                        </SpaceBetween>
                    </Box>
                }
                header={
                    <Header actions={canEdit? <Button onClick={() => setAdjustmentModalVisible(true)}>Adjust balance</Button> : null}>{year} YTD Prior Period Balance</Header>
                }
            ></Table>
            <BalanceAdjustmentModal 
                cli={cli}
                visible={adjustmentModalVisible} 
                isSaving={balanceAdjustmentIsSaving} 
                savingError={balanceAdjustmentError}
                onAdjustmentSubmitted={onAdjustBalanceSubmitted}
                onDismiss={() => setAdjustmentModalVisible(false)} />
        </>
}