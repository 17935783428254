import React, {useEffect, useState} from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {
    Box,
    Button,
    PropertyFilterProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {
    Container, FormField,
    Grid,
    Header,
    Select,
    SelectProps
} from "@amzn/awsui-components-react/polaris";
import TPTaxLefReportFilterSelector from "src/components/reports/lefReport/TPTaxLefReportFilterSelector";
import {
    createGetTPReportFiltersRequestPayloadForLEFReport,
    FilterOption,
    FilterProperty,
    GetTPReportFiltersRequest
} from "src/models/reports/GetTPReportFilters";
import TPPropertyFilter from "src/components/shared/TPPropertyFilter";
import {GetFunctionalSegmentCategoriesByEntityRequest} from "src/models/reports/GetFunctionalSegmentCategoriesByEntity";
import {FetchReportRequest, PaginationConfig} from "src/models/reports/FetchReport";
import {DownloadTPTaxMultiCLIReportRequest} from "src/models/reports/DownloadTPTaxMultiCLIReport";
import {TPTaxReportMapResponse} from "src/models/reports/TPTaxReport";
import CONSTANTS from "src/utils/constants";
import TPMultiCLIReportTableGrid from "src/components/reports/TPMultiCLIReportTableGrid";
import TPMultiCLIReportCollectionPreferences from "src/components/shared/TPMultiCLIReportCollectionPreferences";
import TPMultiCLIReportPagination from "src/components/shared/TPMultiCLIReportPagination";
import {useHistory, useParams} from "react-router-dom";
import {extractCompanyCodeFromCompanyCodeLabel, parseLefUIFilter} from "src/utils/ReportUtils";

export default function TPTaxLefReportView(props: { services: ServiceCollection }) {
    const DEFAULT_PAGE_SIZE: number = 5;
    const DEFAULT_FIRST_PAGE: number = 1;
    const {services} = props;
    const history = useHistory();
    const {filter} = useParams<{ filter: string }>();
    const [currentUrlParams, setCurrentUrlParams] = useState(parseLefUIFilter(decodeURIComponent(filter)) || '');
    const [selectedCompanyCodeOption, setSelectedCompanyCodeOption] = React.useState(null as SelectProps.Option | null);
    const [selectedPeriodOption, setSelectedPeriodOption] = React.useState(null as SelectProps.Option | null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as SelectProps.Option | null);
    const [selectedFunctionalSegmentOption, setSelectedFunctionalSegmentOption] = React.useState({
        label: "All",
        value: "All"
    } as SelectProps.Option);
    const [functionalSegmentCategoriesByEntityRequestPayload, setFunctionalSegmentCategoriesByEntityRequestPayload] =
        React.useState(null as GetFunctionalSegmentCategoriesByEntityRequest | null);
    const [functionalSegmentCategoriesByEntity, functionalSegmentCategoriesByEntityLoading, functionalSegmentCategoriesByEntityError] =
        services.reportsService.getFunctionalSegmentCategoriesByEntity(functionalSegmentCategoriesByEntityRequestPayload);
    const [filtersDataRequestPayload, setFiltersDataRequestPayload] = React.useState(null as GetTPReportFiltersRequest | null);
    const [filtersData, filtersDataLoading, filtersDataError] = services.reportsService.getTPReportFilters(filtersDataRequestPayload);
    const [filterProperties, setFilterProperties] = React.useState([] as FilterProperty[]);
    const [filterOptions, setFilterOptions] = React.useState([] as FilterOption[]);
    const [downloadReportRequestPayload, setDownloadReportRequestPayload] = useState(null as unknown as DownloadTPTaxMultiCLIReportRequest);
    const [downloadReportResponse, downloadTPTaxReportLoading, downloadTPTaxReportError] = services.reportsService.downloadTPTaxMultiCLIReport(downloadReportRequestPayload);
    const [fetchReportRequest, setFetchReportRequest] = React.useState(null as FetchReportRequest | null);
    const [fetchReportResponse, fetchReportLoading, fetchReportError] = services.reportsService.fetchReportData(fetchReportRequest);
    const [reportData, setReportData] = React.useState(null as TPTaxReportMapResponse | null);

    const [searchFilterQuery, setSearchFilterQuery] = React.useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    });
    const [preferences, setPreferences] = React.useState({
        pageSize: DEFAULT_PAGE_SIZE
    });
    const [pageConfig, setPageConfig] = React.useState<PaginationConfig>({
        pageNumber: DEFAULT_FIRST_PAGE,
        pageSize: DEFAULT_PAGE_SIZE
    });
    const [lastPage, setLastPage] = React.useState(DEFAULT_FIRST_PAGE);

    useEffect(() => {
        if (!filtersDataError && !functionalSegmentCategoriesByEntityError && !downloadTPTaxReportError && !fetchReportError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(
            filtersDataError || functionalSegmentCategoriesByEntityError || downloadTPTaxReportError || fetchReportError);
    }, [filtersDataError, functionalSegmentCategoriesByEntityError, downloadTPTaxReportError, fetchReportError]);

    useEffect(() => {
        if (selectedCompanyCodeOption && selectedPeriodOption) {
            setFunctionalSegmentCategoriesByEntityRequestPayload({
                entityCode: selectedCompanyCodeOption.value as string,
                period: selectedPeriodOption.value as string
            });
        }
    }, [selectedCompanyCodeOption, selectedPeriodOption]);

    useEffect(() => {
        if (filtersData) {
            setFilterProperties(filtersData.filterProperties);
            setFilterOptions(filtersData.filterOptions);
        }
    }, [filtersData]);

    useEffect(() => {
        if (fetchReportResponse && fetchReportResponse.payload) {
            const payload = JSON.parse(fetchReportResponse.payload);
            setReportData(payload.tpTaxReportJSONData);
            setLastPage(payload.lastPage);
        }
    }, [fetchReportResponse]);

    useEffect(() => {
        if (downloadReportResponse && downloadReportResponse.reportURL) {
            window.open(downloadReportResponse.reportURL);
        }
    }, [downloadReportResponse])

    useEffect(() => {
        if (selectedAccountingDayOption && selectedPeriodOption && selectedCompanyCodeOption) {
            setFetchReportRequest({
                reportName: CONSTANTS.REPORT_TYPES.TP_TAX_LEF_REPORT,
                params: {
                    accountingDay: selectedAccountingDayOption?.value as string,
                    period: selectedPeriodOption?.value as string,
                    providerCompanyCode: selectedCompanyCodeOption?.value as string,
                    functionalSegmentCategory: selectedFunctionalSegmentOption?.value as string
                },
                paginationConfig: pageConfig,
                filterConfig: {
                    propertyFilters: [...searchFilterQuery.tokens],
                    propertyFiltersOperation: searchFilterQuery.operation
                }
            });
        }
    }, [pageConfig]);

    useEffect(() => {
        if (selectedAccountingDayOption && selectedPeriodOption && selectedCompanyCodeOption) {
            setPageConfig({
                pageNumber: DEFAULT_FIRST_PAGE, pageSize: preferences.pageSize
            });
        }
    }, [preferences, searchFilterQuery, selectedFunctionalSegmentOption]);

    useEffect(() => {
        if (currentUrlParams.companyCodeLabel
            && !(selectedAccountingDayOption && selectedPeriodOption && selectedCompanyCodeOption)
        ) {
            setSelectedAccountingDayOption({
                value: currentUrlParams.accountingDay,
                label: currentUrlParams.accountingDay
            });
            setSelectedPeriodOption({value: currentUrlParams.period, label: currentUrlParams.period});
            setSelectedCompanyCodeOption({
                value: extractCompanyCodeFromCompanyCodeLabel(currentUrlParams.companyCodeLabel),
                label: currentUrlParams.companyCodeLabel
            });
            setFiltersDataRequestPayload(
                createGetTPReportFiltersRequestPayloadForLEFReport(currentUrlParams.accountingDay as string,
                    currentUrlParams.period as string,
                    extractCompanyCodeFromCompanyCodeLabel(currentUrlParams.companyCodeLabel))
            );
        }

        if (selectedAccountingDayOption && selectedPeriodOption && selectedCompanyCodeOption) {
            setFiltersDataRequestPayload(
                createGetTPReportFiltersRequestPayloadForLEFReport(selectedAccountingDayOption.value as string,
                    selectedPeriodOption.value as string,
                    selectedCompanyCodeOption.value as string
                )
            )
        }

        setSearchFilterQuery({
            tokens: [],
            operation: "and"
        });
    }, [currentUrlParams]);

    const onDownloadClicked = () => {
        setDownloadReportRequestPayload({
            reportName: CONSTANTS.REPORT_TYPES.TP_TAX_LEF_REPORT,
            params: {
                period: selectedPeriodOption?.value as string,
                providerCompanyCode: selectedCompanyCodeOption?.value as string,
                accountingDay: selectedAccountingDayOption?.value as string,
                functionalSegmentCategory: selectedFunctionalSegmentOption?.value as string
            },
            filterConfig: (searchFilterQuery.tokens.length === 0) ? null : {
                propertyFilters: [...searchFilterQuery.tokens],
                propertyFiltersOperation: searchFilterQuery.operation
            }
        })
    }

    function handlePageChange(pageNumber: number, pageSize: number) {
        setPageConfig({
            pageNumber: pageNumber,
            pageSize: pageSize
        });
    }

    return (
        <SpaceBetween direction="vertical" size="xl">
            <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                <TPTaxLefReportFilterSelector
                    services={services}
                    selectedCompanyCode={selectedCompanyCodeOption}
                    selectedPeriodOption={selectedPeriodOption}
                    selectedAccountingDayOption={selectedAccountingDayOption}
                    onCompanyCodeSelected={(companyCodeOption) => setSelectedCompanyCodeOption({
                        label: companyCodeOption?.label,
                        value: companyCodeOption?.value
                    })}
                    onPeriodSelected={(periodOption) => setSelectedPeriodOption({
                        label: periodOption?.label,
                        value: periodOption?.value
                    })}
                    onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                        label: accountingDayOption?.label,
                        value: accountingDayOption?.value
                    })}
                />
                <Box padding={{top: "xl"}}>
                    <SpaceBetween direction="horizontal" size="l">
                        <Button
                            variant="primary"
                            onClick={() => {
                                const newUrlParams = `${selectedCompanyCodeOption?.label}+${selectedPeriodOption?.value}+${selectedAccountingDayOption?.value}`;
                                setCurrentUrlParams(parseLefUIFilter(newUrlParams));
                                history.push(`/reports/lef/${encodeURIComponent(newUrlParams.replace(/\./g, '*'))}`);
                            }}
                            disabled={!(!!selectedCompanyCodeOption && !!selectedPeriodOption && !!selectedAccountingDayOption)}
                            loading={fetchReportLoading}
                        >
                            View Report
                        </Button>
                    </SpaceBetween>
                </Box>
                <Box/>
            </Grid>
            <Container header={
                <>
                    <Header
                        variant={"h2"}
                        actions={
                            <Button
                                disabled={(reportData === null) || fetchReportLoading}
                                onClick={onDownloadClicked}
                                loading={downloadTPTaxReportLoading}>
                                Download Full Report
                            </Button>}
                    >
                        Report Details
                    </Header>

                    <Grid gridDefinition={[
                        {colspan: 4},
                        {colspan: 4},
                        {colspan: 4}
                    ]}>
                        <FormField label="&nbsp;">
                            <TPPropertyFilter
                                filterProperties={filterProperties}
                                filterOptions={filterOptions}
                                filtersDataLoading={filtersDataLoading}
                                query={searchFilterQuery}
                                setQuery={setSearchFilterQuery}
                            />
                        </FormField>
                        <FormField label="Functional Segment">
                            <Select
                                statusType={functionalSegmentCategoriesByEntityLoading ? "loading" : "finished"}
                                loadingText="Fetching Functional Segments"
                                selectedOption={selectedFunctionalSegmentOption}
                                onChange={({detail}) =>
                                    setSelectedFunctionalSegmentOption(detail.selectedOption)
                                }
                                options={
                                    functionalSegmentCategoriesByEntity == null
                                        ? []
                                        : functionalSegmentCategoriesByEntity.functionalSegmentCategoriesList.map(
                                            (functionalSegmentCategoryByEntity) => ({
                                                label: functionalSegmentCategoryByEntity,
                                                value: functionalSegmentCategoryByEntity,
                                            })
                                        )
                                }
                                placeholder="Select Option"
                                virtualScroll={true}
                                filteringType="auto"
                                disabled={(reportData === null) || fetchReportLoading}
                            />
                        </FormField>
                        <Box float={"right"} padding={{top: "xl"}}>
                            <SpaceBetween size={"xs"} direction={"horizontal"}>
                                <TPMultiCLIReportPagination
                                    currentPageIndex={pageConfig.pageNumber}
                                    pagesCount={lastPage}
                                    pageSize={pageConfig.pageSize}
                                    disabled={fetchReportResponse === null || fetchReportLoading}
                                    handlePageChange={handlePageChange}
                                />
                                <TPMultiCLIReportCollectionPreferences
                                    fetchReportResponse={fetchReportResponse}
                                    fetchReportLoading={fetchReportLoading}
                                    setPreferences={setPreferences}
                                    preferences={preferences}
                                    options={[5, 10, 15]}
                                />
                            </SpaceBetween>
                        </Box>
                    </Grid>
                </>
            }>
                {
                    reportData && <TPMultiCLIReportTableGrid
                        reportData={reportData}
                        services={services}
                        fetchReportLoading={fetchReportLoading}
                    />
                }
            </Container>
        </SpaceBetween>
    );
}
