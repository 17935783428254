import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, FormField, Grid, Input, KeyValuePairs, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import StringUtils from 'src/utils/stringUtils';
import { CLI } from 'src/models/common/CLI';
import CONSTANTS from 'src/utils/constants';

export default function BalanceAdjustmentModal(props: {
    cli?: CLI,
    visible: boolean, 
    isSaving: boolean, 
    savingError?: string, 
    onAdjustmentSubmitted: (adjustmentBaseAmount:number, adjustmentTotalAmount: number, calculationNumber: string) => void, 
    onDismiss: () => void
}) {
    const {cli,visible, isSaving, savingError, onAdjustmentSubmitted, onDismiss} = props;
    const [baseAdjustmentValue, setBaseAdjustmentValue] = useState('');
    const [totalAdjustmentValue, setTotalAdjustmentValue] = useState('');
    const [formIsValid, setFormIsValid] = useState(false);
    const [formTouched, setFormTouched] = useState(false);
    const [baseInputError, setBaseInputError] = useState('');
    const [totalInputError, setTotalInputError] = useState('');
    const cliHasBeatQualifier = CONSTANTS.BEAT_QUALIFIERS.includes(cli?.beat || "");

    const validateBaseAmount = () => {
        let error = '';
        if (StringUtils.isNullOrEmpty(baseAdjustmentValue)){
            error = "A base amount is required.";
        }
        else if (isNaN(parseFloat(baseAdjustmentValue))){
            error = "The entered base amount is not a valid number.";
        }
        setBaseInputError(error);
        return StringUtils.isNullOrEmpty(error);
    }

    const validateTotalAmount = () => {
        let error = '';
        if (StringUtils.isNullOrEmpty(totalAdjustmentValue)){
            error = `${cliHasBeatQualifier? "A total" : "An adjustment"} amount is required.`;
        }
        else if (isNaN(parseFloat(totalAdjustmentValue))){
            error = `The entered${cliHasBeatQualifier && ' total'} amount is not a valid number.`;
        }
        setTotalInputError(error);
        return StringUtils.isNullOrEmpty(error);
    }

    const validateAmounts = () => {
        let error = '';

        if (parseFloat(totalAdjustmentValue) < parseFloat(baseAdjustmentValue)){
            error = `The entered total amount is less than the base amount.`;
        }
        setTotalInputError(error);
        return StringUtils.isNullOrEmpty(error);
    }



    useEffect(() => {
        if (!cliHasBeatQualifier || !formTouched){
            return;
        }
        validateBaseAmount();
    }, [baseAdjustmentValue])

    useEffect(() => {
        if (!formTouched){
            return;
        }
        validateTotalAmount();
    }, [totalAdjustmentValue])

    useEffect(() => {
        if (!formTouched){
            return;
        }
        setFormIsValid(validateTotalAmount() && (!cliHasBeatQualifier || (validateBaseAmount() && validateAmounts())))
    }, [formTouched, baseAdjustmentValue, totalAdjustmentValue])

    const onConfirmClicked = () => {
        if (cliHasBeatQualifier){
            onAdjustmentSubmitted(Number.parseFloat(baseAdjustmentValue), Number.parseFloat(totalAdjustmentValue), cli?.calculationNumber || '');
        }
        else {
            onAdjustmentSubmitted(Number.parseFloat(totalAdjustmentValue), Number.parseFloat(totalAdjustmentValue), cli?.calculationNumber || '');
        }
    };

    useEffect(() => {
        setBaseInputError('');
        setTotalInputError('');
        setBaseAdjustmentValue('');
        setTotalAdjustmentValue('');
        setFormIsValid(false);
    }, [visible])

    



    return <Modal
            onDismiss={onDismiss}
            size="medium"
            visible={visible}
            footer={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onDismiss}>Cancel</Button>
                    <Button variant="primary" onClick={onConfirmClicked} disabled={!formIsValid || isSaving}>{isSaving? 'Saving...' : 'Confirm'}</Button>
                </SpaceBetween>
            </Box>
            }
            header="Adjust Balance"
        >
            <SpaceBetween direction="vertical" size="m">
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <KeyValuePairs items={[{label:"CLI", value: cli?.calculationNumber}]}></KeyValuePairs>
                    <KeyValuePairs items={[{label:"Accounting Output (BEAT)", value: cli?.beat}]}></KeyValuePairs>
                </Grid>
                {cliHasBeatQualifier &&
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <FormField label="Base adjustment amount" errorText={formTouched && baseInputError}>
                            <Input placeholder='Enter base amount' inputMode="numeric" onChange={({ detail }) => { setFormTouched(true); setBaseAdjustmentValue(detail.value)}}  value={baseAdjustmentValue}/>
                        </FormField>
                    </Grid>
                }
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <FormField label={cliHasBeatQualifier? "Total adjustment amount" : "Adjust monthly balance"} errorText={formTouched && totalInputError}>
                        <Input placeholder={cliHasBeatQualifier? 'Enter total amount' : 'Enter adjustment amount'} inputMode="numeric" onChange={({ detail }) => {setFormTouched(true); setTotalAdjustmentValue(detail.value)}}  value={totalAdjustmentValue}/>
                    </FormField>
                </Grid>
                {cliHasBeatQualifier && <StatusIndicator type="info">Markup amount is calculated by subtracting base from total.</StatusIndicator>}
                {!StringUtils.isNullOrEmpty(savingError) && <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Your adjustment could not be saved"
                    >
                    {savingError}
                    </Alert>
                }
            </SpaceBetween>
    </Modal>
}