import React, { useMemo, useEffect, useState, useContext } from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import YtdPeriodBalance from 'src/models/periodCharges/YtdPeriodBalance';
import { Box, SpaceBetween, Button, Pagination, Header, StatusIndicator, FormField, Select, Grid, Input } from '@amzn/awsui-components-react';
import PeriodChargesLOVsResponse from 'src/models/periodCharges/PeriodChargesLOVsResponse';
import { PeriodChargesContext } from './PeriodChargesView';
import ATPCLIsAutosuggest from '../shared/ATPCLIsAutosuggest';
import { ACTIONS } from 'src/services/period-charges/PeriodChargesReducer';
import StringUtils from 'src/utils/stringUtils';


export default function TopYtdBalancesGrid(props: {
        filterLOVs: PeriodChargesLOVsResponse | null,
        clisAutoSuggestList: string[],
        onFilterChanged?: (selectedFilters:any) => void,
        onExportAllCLIs?: () => void,
        onApproveBalance?: () => void
        onSelectionChanged?: (selectedYtdBalances:any[]) => void,
    }) {
    const {filterLOVs, clisAutoSuggestList, onFilterChanged, onApproveBalance, onExportAllCLIs, onSelectionChanged} = props;
    const { state, dispatch, services } = useContext(PeriodChargesContext);
    const columnDefinitions = useMemo(() => [
        {
          id: "calculationNumber",
          header: "CLI",
          cell: (e: YtdPeriodBalance) => e.calculationNumber,
          width: 300,
          minWidth: 180,
          isRowHeader: true
        },
        {
          id: "currency",
          header: "Currency",
          cell: (e: YtdPeriodBalance) => e.calculationCurrency,
          width: 110,
          minWidth: 110,
        },
        {
          id: "beginningBalance",
          header: "YTD beginning balance",
          cell: (e: YtdPeriodBalance) => <Box float="right">{e.ytdBeginningBalance == null? '-' : services.formattingService.formatNumber(e.ytdBeginningBalance, true, 2)}</Box>,
          width: 200,
          minWidth: 150
        },
        {
          id: "month",
          header: "Month",
          cell: (e: YtdPeriodBalance) => e.month,
          width: 100,
          minWidth: 60
        },
        {
          id: "year",
          header: "Year",
          cell: (e: YtdPeriodBalance) => e.year,
          width: 100,
          minWidth: 60
        },
        {
          id: "updatedOn",
          header: "Updated on",
          cell: (e: YtdPeriodBalance) => e.updatedOn || "-",
          width: 200,
          minWidth: 170
        }
      ], []);

    const { searchPeriodChargesIsLoading, searchPeriodChargesError, periodCharges, selectedPeriodCharges, exportPeriodChargesFileURL, exportPeriodChargesStatus } = state;
    const [
        selectedFilterAssignee,
        setSelectedFilterAssignee
    ] = useState({ value: "All" } as any);

    const [
        selectedStatusFilter,
        setSelectedStatusFilter
    ] = useState({ value: "All" } as any);
    
    const [
        selectedCLIFilter,
        setSelectedCLIFilter
    ] = useState('');

    const [
        selectedYearFilter,
        setSelectedYearFilter
    ] = useState({ value: "All" } as any);

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(exportPeriodChargesFileURL)){
            return;
        }
        window.open(exportPeriodChargesFileURL);
    },[exportPeriodChargesFileURL])

    useEffect(() => {
        if (filterLOVs == null){
            return;
        }
        filterLOVs.assignees.unshift("All");
        filterLOVs.years.unshift("All");
        filterLOVs.statuses.unshift("All");
    }, [filterLOVs])

    useEffect(() => {
        if (onFilterChanged == null){
            return;
        }
        onFilterChanged({
            selectedCLI: selectedCLIFilter, 
            selectedAssignee: selectedFilterAssignee?.value, 
            selectedStatus: selectedStatusFilter?.value, 
            selectedYear: selectedYearFilter?.value
        })
        dispatch(ACTIONS.SET_SELECTED_PERIOD_CHARGES.withPayload([]));
    }, [selectedCLIFilter, selectedFilterAssignee, selectedStatusFilter, selectedYearFilter])

    const onExportAllCLIsClicked = () => {
        if (onExportAllCLIs != null){
            onExportAllCLIs();
        }
    }
    
    const onApproveBalanceClicked = () => {
        if (onApproveBalance != null){
            onApproveBalance();
        }
    }

    useEffect(() => {
        if (onSelectionChanged){
            onSelectionChanged(selectedPeriodCharges);
        }
    }, [selectedPeriodCharges])

    
    
    const renderTableFilters = function (){
        return <Grid
        gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 2 }]}
      >
            {/* <FormField label="Year">
                <Select selectedOption={selectedYearFilter}
                      onChange={({ detail }) =>
                        setSelectedYearFilter(detail.selectedOption)
                      }
                    options={filterLOVs?.years.map(x => ({label: x, value: x}))}
                ></Select>
            </FormField>
            <FormField label="Assignee">
                <Select selectedOption={selectedFilterAssignee} filteringType="auto"
                    onChange={({ detail }) =>
                        setSelectedFilterAssignee(detail.selectedOption)
                    }
                    options={filterLOVs?.assignees.map(x => ({label: x, value: x}))}
                ></Select>
            </FormField> */}
            <FormField 
                // label={<span>&nbsp;</span>}
                label="Type full CLI to start search"
                errorText={searchPeriodChargesError}
                >
                    <ATPCLIsAutosuggest clis={clisAutoSuggestList} initialValue='' onCLISelected={(value:string) => setSelectedCLIFilter(value)} onAutocompleteTextChanged={(value:string) => {}} />
            </FormField>
            {/* <FormField label="Status">
                <Select selectedOption={selectedStatusFilter}
                    onChange={({ detail }) =>
                        setSelectedStatusFilter(detail.selectedOption)
                    }
                    options={filterLOVs?.statuses.map(x => ({label: x, value: x}))}
                ></Select>
            </FormField> */}
        </Grid>;
    }
    
    return <Table 
        renderAriaLive={({
            firstIndex,
            lastIndex,
            totalItemsCount
        }) =>
            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
        }
        columnDefinitions={columnDefinitions}
        items={periodCharges}
        loading={searchPeriodChargesIsLoading}
        loadingText="Loading resources"
        resizableColumns
        empty={
        <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
        >
            <SpaceBetween size="m">
            <b>No resources</b>
            </SpaceBetween>
        </Box>
        }
        header={
        <Header 
            counter={
                selectedPeriodCharges.length
                ? `(${selectedPeriodCharges.length}/${periodCharges.length})`
                : `(${periodCharges.length})`
            }
            actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button onClick={onExportAllCLIsClicked} disabled={!StringUtils.isNullOrEmpty(exportPeriodChargesStatus)}>{StringUtils.isNullOrEmpty(exportPeriodChargesStatus)? "Export all CLIs": exportPeriodChargesStatus}</Button>
                  {/* <Button variant="primary" onClick={onApproveBalanceClicked}>
                    Approve balance
                  </Button> */}
                </SpaceBetween>
            }
        >YTD Prior Period Balance</Header>
        }
        pagination={
        <Pagination currentPageIndex={1} pagesCount={1} />
        }
        selectionType="multi"
        selectedItems={selectedPeriodCharges}
        onSelectionChange={({detail}) => dispatch(ACTIONS.SET_SELECTED_PERIOD_CHARGES.withPayload(detail.selectedItems))}
        filter={renderTableFilters()}
        
    ></Table>
}

function renderApprovalStatus(approvalStatus: string) {

    switch(approvalStatus){
        case "Pending approval":
            return <StatusIndicator type="pending">{approvalStatus}</StatusIndicator>;
        case "Approved":
            return <StatusIndicator type='success'>{approvalStatus}</StatusIndicator>;
        default:
            return approvalStatus;
    }
}

