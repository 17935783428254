import apiService from "src/services/ApiCallService";
import {useEffect, useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import YtdPeriodBalance from "src/models/periodCharges/YtdPeriodBalance";
import PeriodChargesLOVsResponse from "src/models/periodCharges/PeriodChargesLOVsResponse";
import DateUtils from "src/utils/dateUtils";


export default class PeriodChargesService {
    

    getPeriodCharges(fetchFlag: string, payload: string) : [YtdPeriodBalance[], boolean, string] {
        const [results, setResults] = useState([] as YtdPeriodBalance[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.getPeriodCharges(payload);
                    const json = (await response.json()).periodChargeList as YtdPeriodBalance[];
                    setResults(formatYtdBalances(json));
                    setError('');
                }
                catch (ex) {
                    setResults([]);
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(fetchFlag) && !StringUtils.isNullOrEmpty(payload)) {
                fetchData();
            }
        }, [fetchFlag, payload]);
        return [results, loading, error];
    }

    getLOVs(): [PeriodChargesLOVsResponse | null, boolean, string]{
        const [results, setResults] = useState(null as PeriodChargesLOVsResponse | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    //const response = await apiService.getYtdBalances(payload);
                    //const json = await response.json() as YtdPeriodBalance[];
                    setResults({
                        years: ["2025","2024","2023"],
                        assignees: ["payara", "anirvis", "chashank"],
                        statuses: ["Pending approval", "Approved", "No approval needed","Overdue"],
                        clis: []
                    });
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }, []);

        return [results, loading, error];
    }

    saveAdjustment(payload: any): [any, any, any] {
        const [results, setResults] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.savePeriodChargeAdjustment(payload.calculationNumber, 
                        {   
                            calculationNumber: payload.calculationNumber,
                            adjustmentBaseAmount: payload.adjustmentBaseAmount, 
                            adjustmentTotalAmount: payload.adjustmentTotalAmount
                        });
                    const json = await response.json();
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }
}

function formatYtdBalances(balances: YtdPeriodBalance[]): YtdPeriodBalance[] {
    return balances.map(x => {
            const trimmedPeriodId = x.periodId.substring(0,6);
            return {
            ...x,
            updatedOn: DateUtils.formatTimestamp(x.updatedOn),
            createDateFormatted: DateUtils.formatTimestamp(x.createDate?.toString()),
            month: DateUtils.formatDate(trimmedPeriodId,"MMM"),
            year: DateUtils.formatDate(trimmedPeriodId, "YYYY")
        }
    });
}
